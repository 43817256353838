import {Injectable} from '@angular/core';
import {TokenParams} from '../../global/tokenParams';

@Injectable()
export class localStorageServices {
    public setAuthorizationData(Auth:TokenParams):void{
        localStorage.setItem('Authorization',JSON.stringify(Auth));
    }

    public getValueFromLocalStorage():TokenParams{
        let datatoken= JSON.parse(localStorage.getItem('Authorization'));
        return datatoken==null ? null:datatoken;
    }

    public removeValueFromLocalStorage():void{
        localStorage.setItem('Authorization',JSON.stringify({}));
    }

    public setVentaData(Auth:any):void{
        localStorage.setItem('venta',JSON.stringify(Auth));
    }

    public getValueVenta():any{
        let datatoken= JSON.parse(localStorage.getItem('venta'));
        return datatoken==[] ? null:datatoken;
    }

    public removeValueCliente():void{
        localStorage.setItem('cliente',JSON.stringify({}));
    }

    public setClienteData(Auth:any):void{
        localStorage.setItem('cliente',JSON.stringify(Auth));
    }

    public getValueCliente():any{
        let datatoken= JSON.parse(localStorage.getItem('cliente'));
        return datatoken==null ? null:datatoken;
    }

    public removeValueVenta():void{
        localStorage.setItem('venta',JSON.stringify({}));
    }


    public verPermiso(codigo:string):any{
        let datatoken= JSON.parse(localStorage.getItem('Authorization'));
        return datatoken.grupo.permisocomprobantes.filter(permiso => permiso.comprobantelectronico.tipocomprobante.codigo === codigo);
    }
}