import { Routes } from '@angular/router';
import {AuthGuard} from '../auth/auth.guard';
import { GroupListComponent } from './groups-list/grouplist.component';

export const GroupsRoutes: Routes =[{
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },{
    path: '',
    children: [{
      path: 'grouplist',
      component: GroupListComponent,
      canActivate: [AuthGuard],
      data: {
        title: 'Lista de grupos',
        urls: [
          { title: 'Home', url: '/home' },
          { title: 'Página de inicio' }
        ]
      }
    }]
  }];
