import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule, 
        MatIconModule, 
        MatCardModule, 
        MatButtonModule, 
        MatListModule, 
        MatGridListModule,
        MatProgressBarModule, 
        MatDialogModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatToolbarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

import {UserAddModalComponent} from './add/useraddmodal.component';
import {UserEditModalComponent} from './edit/usereditmodal.component';
import {GroupsModule} from '../../group-pages/groups.module';
import { UserAdminModalComponent } from './admin/useradminmodal-component';
import { UserEditAdminModalComponent } from './editadmin/usereditadminmodal-component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations:[UserAddModalComponent,
                UserEditModalComponent,
                UserAdminModalComponent,
                UserEditAdminModalComponent],
    imports: [
              CommonModule,
              FormsModule,
              ReactiveFormsModule,
              MatIconModule,
              MatCardModule,
              MatButtonModule,
              ReactiveFormsModule,
              MatListModule,
              MatProgressBarModule,
              MatDialogModule,
              MatMenuModule,
              MatToolbarModule,
              FlexLayoutModule,
              MatInputModule,
              MatButtonToggleModule,
              MatGridListModule,   
              MatSelectModule,
              GroupsModule,
              NgxDatatableModule,
              NgSelectModule
             ],
    entryComponents: [UserAddModalComponent,
                UserEditModalComponent,
                UserAdminModalComponent,
                UserEditAdminModalComponent],
             
  })
  export class UserAddModalModule {}