import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NotificationServices } from '../../appauth/notification/notificatio.services';
import { Impresora } from '../../global/global.model';
import { localStorageServices } from '../../appauth/auth/localStorageServices';
import { ListaComercialesServices } from '../../global/glogal.services';
import { DataSharingService } from '../../global/ dataService.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  color = 'green';
  showSettings = false;
  showMinisidebar = false;
  showDarktheme = false;
  showRtl = false;
  userdata: any;
  grupos = [];

  public innerWidth: any;

  public config: PerfectScrollbarConfigInterface = {};

  insertar:boolean=false;
  dataImpresora={} as Impresora;
  Persona ={} as any;
  actulizar:boolean=false;
  ulrsimp='https://';
  infoMetraje:any;

  constructor(public router: Router,
    private Noti:NotificationServices,
              public servicioAdministradores: ListaComercialesServices,
              private DataSharing: DataSharingService,
              private route: ActivatedRoute,
              public localdata: localStorageServices,) {
                this.DataSharing.SharingData.subscribe((res: any) => {  
                  this.infoMetraje = res;  
                })  
              }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
    let dataSend = { 'user_id': this.userdata.datos.id } as any;
    this.grupos = this.userdata.datos.group_users;
    this.grupos.forEach((element,index) => {
      this.grupos[index].select =false;
      if(this.userdata.grupo!=undefined){
        if(element.group_id==this.userdata.grupo.group_id){
          this.grupos[index].select =true;
        }
      }
    });
    this.listaImpresora();
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/dashboard1']);
    }
    this.handleLayout();
    this.servicioAdministradores.viewInformacionMetraje().subscribe((data:any)=>{
      this.DataSharing.SharingData.next(data.trazos);  
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleLayout();
  }

  toggleSidebar() {
    this.showMinisidebar = !this.showMinisidebar;
  }

  handleLayout() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.showMinisidebar = true;
    } else {
      this.showMinisidebar = false;
    }
  }

  listaImpresora(){
    if(this.userdata.datos!=undefined){
      let dataSend = { 'user_id': this.userdata.datos.id } as any;
      this.servicioAdministradores.viewAdministradoresImpresoras(dataSend).subscribe((datadmin: any) => {
        if(datadmin.impresora!=undefined){
          this.dataImpresora=datadmin.impresora;
          this.ulrsimp='https://'+this.dataImpresora.ip+':3333';
          this.insertar=true;
        }else{
          this.insertar=false;
        }
      });
    }
  }

  seleccionar(data:any){
    this.userdata.grupo=data;
    this.localdata.setAuthorizationData(this.userdata);
    this.grupos = this.userdata.datos.group_users;
    this.grupos.forEach((element,index) => {
      this.grupos[index].select =false;
      if(this.userdata.grupo!=undefined){
        if(element.group_id==this.userdata.grupo.group_id){
          this.grupos[index].select =true;
        }
      }
    });
    this.router.navigate(['home']);
    setInterval(() => {
      window.location.reload();
    }, 1000);
  }

  guardar(data:any){
    this.dataImpresora=data.value;
    this.dataImpresora.user_id=this.userdata.datos.id;
    this.dataImpresora.estado=1;
    this.servicioAdministradores.saveImpresoras(this.dataImpresora).subscribe((dataImpr:any)=>{
      if(dataImpr.estado){
				this.Noti.setNotification(dataImpr).then((datas)=>{
					if(datas){
            this.listaImpresora();
            this.insertar=true;
          
					}
				});
			}else{
				this.Noti.setNotification(dataImpr);
			}
    });
    
  }

  actulizarInfo(){  
    this.servicioAdministradores.updateImpresoras(this.dataImpresora).subscribe((dataImpr:any)=>{
      if(dataImpr.estado){
				this.Noti.setNotification(dataImpr).then((datas)=>{
					if(datas){
            this.actulizar=false;
            this.listaImpresora();
					}
				});
			}else{
				this.Noti.setNotification(dataImpr);
			}
    });
  }

  habiltar(){
    this.actulizar=true;
  }

  actulizarCancel(){
    this.actulizar=false;
  }

  probar(){
    let sendCod = { data: { "empresa": "NATGER CODE"}, ip: this.dataImpresora.ip } as any;
    this.servicioAdministradores.printerPueba(sendCod).subscribe();
  }

  irTrazos(){
    this.router.navigateByUrl('/textiles-config/trazo-rollos', { relativeTo: this.route.parent, skipLocationChange: true });
  }
}
