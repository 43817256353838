import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule, 
        MatIconModule, 
        MatCardModule, 
        MatButtonModule, 
        MatListModule, 
        MatGridListModule,
        MatProgressBarModule, 
        MatMenuModule,
        MatButtonToggleModule,
        MatToolbarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

import { GroupsRoutes } from './groups.routing';
import {GroupsServices} from './groups/groups.services';
import { GroupListComponent} from './groups-list/grouplist.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(GroupsRoutes),
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatListModule,
    MatProgressBarModule,
    MatMenuModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonToggleModule,
    MatGridListModule,
    NgxDatatableModule
  ],
  declarations: [ 
    GroupListComponent
  ],
  providers:[
    GroupsServices
  ]
})

export class GroupsModule {}
