import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../global/global.model';
import {Users} from '../user-pages/user/users.model';
import {TokenParams} from '../../global/tokenParams';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class AuthServices {
    AccessToken:string="";
    constructor(private http: HttpClient) { }
    
    login(data:Users):Observable<TokenParams>{
        return this.http.put<TokenParams>(Globals.BASE_URL_API_REST+'login.json',data);
    } 
    
    logout(){
        return this.http.delete(Globals.BASE_URL_API_REST+'login.json');   
    }
    
    recoverPassword(data:Users){
        let headers = new HttpHeaders().set('Authorization','SmartRoad '+2525258479);
        return this.http.patch(Globals.BASE_URL_API_REST+'restablecerpass.json',data,{ headers: headers });
    }
}
