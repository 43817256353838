import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthServices} from './auth.services';
import { localStorageServices } from './localStorageServices';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  ],
  providers: [  
    AuthServices,
    localStorageServices
  ]
})

export class AuthModule {}
