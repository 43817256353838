import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData, DecimalPipe } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FullComponent } from './layouts/full/full.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
 
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { BlankComponent } from './layouts/blank/blank.component';

import localeEsEC from '@angular/common/locales/es-EC';
registerLocaleData(localeEsEC, 'es-EC');

import {AppauthModule} from './appauth/appauth.module';
import { MatDialogModule } from '@angular/material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ListaComercialesServices } from './global/glogal.services';
import { AppAsclepioModule } from './appasclepio/appasclepio.module';
import { AppInsumoModule } from './appinsumos/appinsumos.module';
import { AppEmpresaModule } from './appempresa/appempresa.module';
import { AppFactureroModule } from './appfactureros/appfacturero.module';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  POSITION,
  SPINNER,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { AppTextilesModule } from './apptextiles/textil.module';
import { DataSharingService } from './global/ dataService.service';
import { AppClienteModule } from './appcliente/appcliente.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#59c0f3",
  "bgsOpacity": 0.3,
  "bgsPosition": "bottom-right",
  "bgsSize": 50,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fgsColor": "#59c0f3",
  "fgsPosition": "center-center",
  "fgsSize": 80,
  "fgsType": "square-jelly-box",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "#59c0f3",
  "pbDirection": "ltr",
  "pbThickness": 4,
  "hasProgressBar": true,
  "text": "SONTEXT",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    BlankComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    RouterModule.forRoot(Approutes,{useHash: true}),
    NgMultiSelectDropDownModule.forRoot(),
    PerfectScrollbarModule,
    AppauthModule,
    MatDialogModule,
    AppAsclepioModule,
    AppInsumoModule,
    AppEmpresaModule,
    AppFactureroModule,
    AppTextilesModule,
    AppClienteModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: LOCALE_ID, useValue: 'es-EC' },
    ListaComercialesServices,
    DataSharingService,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
