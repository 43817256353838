import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Globals, Impresora } from './global.model';


@Injectable()
export class ListaComercialesServices {

	constructor(private http: HttpClient) { }

	viewInformacionMetraje() {
		return this.http.get(Globals.BASE_URL_API_REST + 'lista_rollos_papel.json');
	}

	// viewAdministradoresPorUsuario(data:any){
	// 	return this.http.put(Globals.BASE_URL_API_REST+'opadministradores.json',data);
	// }

	// getDataEmpleadoConfig(data:any):Observable<any[]>{
	//     return this.http.post<any[]>(Globals.BASE_URL_API_REST+'opempleadoconfig.json',data);
	// }

	listasDeImpresoras() {
		return this.http.get(Globals.BASE_URL_API_REST + 'impresoras.json');
	}
	viewAdministradoresImpresoras(data: any) {
		return this.http.get(Globals.BASE_URL_API_REST + 'ap_printers_lista/' + data.user_id + '.json');
	}

	saveImpresoras(data: Impresora) {
		return this.http.post(Globals.BASE_URL_API_REST + 'printers.json', data);
	}

	updateImpresoras(data: Impresora) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'printers/' + data.id + '.json', data);
	}

	printerPagoVenta(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/pagos', data.data, { headers: headers });
	}

	printerPagoVentaAbono(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/pagoabonos', data.data, { headers: headers });
	}

	printerPueba(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/prueba', data.data, { headers: headers });
	}

	printerPagoCopiaVenta(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/pagocopia', data.data, { headers: headers });
	}

	printerCodigoPrueba(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ' + 66630998755432);
		return this.http.post('https://' + data.ip + ':6663/codigo', data.data, { headers: headers });
	}

	viewDisenioDeudas(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_deudas_disenoprendas/' + id + '.json');
	}
	
}